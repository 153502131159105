/* Adjust padding for the profile container for a tighter layout */
.profile-container {
  padding: 5rem; /* Reduced from 10rem to something smaller */
}

/* Table header adjustments for a cleaner look */
.profile-container .mdb-table thead th {
  background-color: #4e5d6c; /* Maintain the dark header */
  color: #ffffff;
  border-bottom: 2px solid #707070;
  text-align: center; /* Center align header text */
  font-size: 1.2rem;
}

/* Align the checkbox to the center */
.profile-container .mdb-table tbody td {
  text-align: center;
  vertical-align: middle;

  font-size: 2rem; /* Larger font size for body content */
}
/* Adjust the width of the image column to make it smaller */
.profile-container .mdb-table tbody td:nth-child(2) {
  max-width: 120px; /* Adjust the max width as needed */
  overflow: hidden; /* Hide overflow */
  /* white-space: nowrap; Prevent text from wrapping */
  word-wrap: break-word;
  text-overflow: ellipsis; /*Add ellipsis if text is too long*/
}
/* Styling the checkbox directly for better control */
/* Ensure checkboxes are centered in the cell */
.profile-container .mdb-table tbody td input[type="checkbox"] {
  display: block;
  margin: 0 auto;
}

.profile-container .mdb-table tbody img {
  width: 300px !important;
  height: auto !important;
}

.profile-container .mdb-table thead th:last-child,
.profile-container .mdb-table tbody td:last-child {
  width: 150px; /* Adjust the width as needed */
}
/* Table column widths */
.profile-container .mdb-table th:nth-child(1), /* Adjust based on the index of your image column */
.profile-container .mdb-table td:nth-child(1) {
  width: 300px; /* Make sure this matches or exceeds the width of your images */
}

.profile-container .mdb-table tbody tr:nth-child(odd) {
  background-color: #f5f5f5; /* Lighter shade for alternating rows */
}

/* Custom checkbox styling */
.profile-container .checkbox-container input:checked ~ .checkmark {
  background-color: #4e5d6c; /* Match header color */
}

/* Button styling */
.profile-container .btn-purchase {
  background-color: #4e5d6c; /* Match header color */
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  font-weight: bold;
}

.profile-container .btn-purchase:hover {
  width: 100%; /* Ensures the container takes full width */
  display: block; /* Ensures the container is a block-level element */
  margin: 0 auto; /* Auto margins for horizontal centering */
  padding: 1rem;
}

/* Home button container fixed at the bottom */
.home-button-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

/* Loading container styling */
.loading-container {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if desired */
  min-height: 100vh; /* Ensure it takes at least the full viewport height */
  color: #4e5d6c; /* Match the color theme */
  font-weight: bold;
}

/* If you want to center it vertically as well, you can adjust the margin-top property */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s infinite linear;
}

/* You may also want to adjust the loading text to be in line with the spinner */
.loading-container > div {
  display: flex;
  align-items: center;
}

/* Backdrop for the modal */
.purchase-summary-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to be on top of other content */
}

/* Modal styling */
.purchase-summary-modal {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure the modal is above the backdrop */
  width: 90%;
  max-width: 500px; /* Adjust as necessary */
  animation: modalAppear 0.3s ease-out forwards;
}

@keyframes modalAppear {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Modal header */
.purchase-summary-modal h3 {
  margin-bottom: 1.5rem;
  color: #333;
  text-align: center;
}

/* List of items in the modal */
.purchase-summary-modal ul {
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.purchase-summary-modal li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

/* Total cost display */
.summary-total {
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

/* Buttons in the modal */
.purchase-summary-modal button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.purchase-summary-modal button.confirm {
  background-color: #4caf50;
  color: white;
}

.purchase-summary-modal button.confirm:hover {
  background-color: #45a049;
}

.purchase-summary-modal button.cancel {
  background-color: #f44336;
  color: white;
  margin-left: 0.5rem;
}

.purchase-summary-modal button.cancel:hover {
  background-color: #d32f2f;
}

/* Profile.css */
.quantity-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.quantity-input {
  text-align: center;
  width: 50px; /* Set a fixed width */
  margin: 0 5px;
  border: 1px solid #ced4da; /* Match Bootstrap's default styling */
  border-radius: 0.25rem; /* Match Bootstrap's default styling */
}

.increment-decrement-btn {
  background: #f8f9fa; /* Match Bootstrap's default button color */
  border: 1px solid #ced4da; /* Match Bootstrap's default border */
  color: #495057; /* Match Bootstrap's default text color */
  padding: 5px;
  border-radius: 0.25rem; /* Match Bootstrap's default styling */
  cursor: pointer; /* To indicate the buttons are clickable */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .profile-container {
    padding: 2rem; /* Adjusted from 5rem to smaller padding */
    margin-top: 5%;
  }
  .profile-container .mdb-table {
    display: block;
    width: 100%;
  }

  .profile-container .mdb-table thead {
    display: none;
  }

  .profile-container .mdb-table tbody tr {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally in flex container */
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    background-color: #fff !important; /* Optional: Adds background color for contrast */
  }

  .profile-container .mdb-table td {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center-align items */
    justify-content: center; /* Center content vertically */
    width: 100%; /* Take full width to use all space */
    padding: 10px;
    text-align: center; /* Ensure text is centered */
    font-size: 1.2rem;
    border: none; /* Removes the bottom border if not needed */
  }

  .profile-container .mdb-table td:last-child {
    border-bottom: none;
  }

  .profile-container .mdb-table img {
    max-width: 100%; /* Adjust the image size */
    height: auto;
    margin-right: 10px;
  }

  .profile-container .quantity-input-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .profile-container .increment-decrement-btn {
    background: #f8f9fa;
    border: 1px solid #ced4da;
    color: #495057;
    padding: 8px 12px; /* Adjust padding to increase tap area */
    cursor: pointer;
    font-size: 1.2rem; /* Increase font size for better visibility */
  }
  
  .profile-container .quantity-input {
    width: 50px; /* Set a fixed width for the input */
    height: 34px; /* Match the height with buttons */
    text-align: center;
    margin: 0 5px; /* Space between buttons and input */
    border: 1px solid #ced4da;
    font-size: 1.2rem; /* Increase font size for better readability */
  }
  
  .profile-container .mdb-table td {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-container .mdb-table tbody td:nth-child(4) { /* Adjust this if the column index is different */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .itemname {
    font-size: 1.7rem !important;
  }
}
