.App {
  text-align: center;
  background-color: #040720;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #040720;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mb-3{
  width: "80%";
  height: "60%";
  background-color: #040720 !important;
  border: none !important;
}

.points-text {
  color: #fff; /* Change text color to white */
  font-size: 1.5rem; /* Increase the font size */
  font-weight: bold; /* Optional: Makes the text bold */
}

.Form {
  color: #fff; /* Change text color to white */
  font-size: 1.5rem; /* Increase the font size */
  font-weight: bold; /* Optional: Makes the text bold */
}

/* In your CSS file */
.main-content {
  margin-top: 60px; /* Adjust this value based on the actual height of your navbar */
}

.flex-shrink-0 {
  flex-shrink: 0; /* Don't allow the image to shrink */
}

.flex-grow-1 {
  flex-grow: 1; /* Allow the text section to grow */
}


/* Make sure the parent of the button has position: relative */
.app-container {
  position: relative;
  /* other styling */
}

/* Style for the view switch button */
.view-switch-button {
  position: fixed; /* Fixed position relative to the viewport */
  bottom: 10px;
  left: 10px;
  padding: 10px;
  background-color: #4a5e74; /* Bootstrap primary color for consistency */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.view-switch-button:hover {
  background-color: #0056b3; /* Darken button on hover */
}
.card-title {
  color: white;
  font-size: 2.0rem; /* Default size for desktop */
}

.card-text {
  color: white;
  font-size: 1.3rem; /* Default size for desktop */
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .card img {
    width: 230%;
    height: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px; /* Optional: adds some space above the image */
  }
  .card-title {
    font-size: 1.3rem; /* Adjust the font size to be smaller */
  }

  .main-content {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .card {
    padding: 10px; /* Provide some padding inside the card */
    margin: 10px 0; /* Add top and bottom margin for spacing */
  }
}
