/* FollowUs.css */
.follow-us-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
    /* ... other styles ... */
  }
  
  .card {
    /* Style for each card (e.g., each social media platform) */
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* ... other styles ... */
  }
  .social-media-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    padding-top: 60px;
  }
  
  .social-media-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .social-media-header {
    background-color: #eee; /* Placeholder color */
    padding: 10px;
    text-align: center;
  }
  
  .social-media-body {
    padding: 10px;
    background-color: white;
  }
  
  .social-media-body a {
    color: blue;
    text-decoration: none;
  }
  
  html, body {
    height: 100%;
    margin: 0;
    background-color: #040720; /* Replace with the color you want */
  }
  